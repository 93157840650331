import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import MembershipApiService from "@/core/services/api.service.membership";
import ApiService from "@/core/services/api.service";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },
    name: "package-permission",
    data() {
        return {
            valid: false,
            voucher: null,
            isSaving: false,
            shop: null,
            shops: [],
            shopsAreLoading: false,
            search: "",
            shopsSearchInput: null,
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    async created() {
        await MembershipApiService.init();
        this.membershipApiInitiated = true;
        this.packagePermissionId = this.$route.params.packagePermissionId;
        let title =
            this.packagePermissionId != null
                ? "Package permission details"
                : "Insert new package permission";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        this.searchShops();
    },
    methods: {
        searchShops() {
            // Items have already been loaded
            if (this.shops.length > 0) return;

            // Items have already been requested
            if (this.shopsIsLoading) return;

            this.shopsIsLoading = true;
            ApiService.query("api/shops/dropdown-list")
                .then(res => {
                    this.shops = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.shopsIsLoading = false));
        },
        previousState() {
            this.$router.go(-1);
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.saveSuperMembership(this.shop);
            }
        },
        saveSuperMembership(shop) {
            this.isSaving = true;
            this.loading = true;
            let shopDTO = {};
            shopDTO['shopId'] = shop.id.toString();
            shopDTO['shopName'] = shop.name;
            if (this.voucher != null)
                shopDTO['voucher'] = this.voucher;
            MembershipApiService.post(`/Permissions/CreateShopPermissionDefinition`, shopDTO)
                .then(response => {
                    this.$log.debug("Super membership created: " + response);
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                    this.previousState();
                });
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
